import React from 'react'

export default function Tutorials() {
  return(
    <div class = "container" id = "div-main">

      <div id = "main1">
        <h2>Will I still add tutorials?</h2>
      </div>
        <div id = "main1a">
          Here.
        </div>
        <div id = "main2">
        </div>
        <div id = "main2a">
        </div>
        <div id = "main3">
        </div>
        <div id = "main3a">
        </div>
        <a href = "#top"><div id ="back-to-top">↑</div></a>
    </div>
  )
}